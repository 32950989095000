import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, SubmitButton } from '../../../../../components';
import { useMeterTariffPricesConfiguration } from '../../hooks/use-meter-tariff-prices-configuration';
import { TariffPricesForm } from '../../types';

const TariffPrices: React.FC = () => {
  const { t: tc } = useTranslation('common');
  const form = useForm<TariffPricesForm>({
    defaultValues: {
      tariff: '',
      prices: [],
    },
  });
  const { handleSubmitPrices } = useMeterTariffPricesConfiguration(form);
  const { prices } = form.watch();

  const onSubmit = () => {
    const values = form.getValues();
    handleSubmitPrices(values);
  };

  return (
    <div>
      <FormProvider {...form}>
        <form>
          {prices.map((p, i) => (
            <Controller
              key={p.pricingTier}
              name={`prices.${i}.price`}
              control={form.control}
              rules={{ required: tc('isRequired') as string }}
              render={({ field, fieldState: { error } }) => (
                <div className="grid-list-24">
                  <Input
                    label={`Cena dla poziomu cenowego: ${p.pricingTier} (zł)`}
                    placeholder={'0 zł'}
                    name={field.name}
                    onChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                    {...(error ? { errorMessage: error.message } : {})}
                  />
                </div>
              )}
            />
          ))}
        </form>
      </FormProvider>
      <SubmitButton type="button" onClick={onSubmit} />
    </div>
  );
};

export default TariffPrices;
