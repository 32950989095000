import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useMutation } from '@apollo/client';
import { useGetTariffs } from '../../../../api/modules/optimizer/optimizer.hooks';
import {
  ChannelTypeInternal,
  TariffConfiguration,
  UpdateTariffConfigurationsMutation,
  UpdateTariffConfigurationsMutationVariables,
} from '../../../../data-access/gql-types/graphql';
import { UPDATE_TARIFF_CONFIGURATIONS } from '../../../../data-access/mutations/channels';
import { useBackdropContext, useInstallation } from '../../../../hooks';
import { useChannelErrors } from '../../../../hooks/backend-errors/use-channel-errors';
import { useChannelDetailsContext } from '../../../../hooks/channel-details';
import { ChannelMeterInterface } from '../../../../types';
import { toastSuccess } from '../../../../utils/toast';
import { tariffKind } from '../../../channel-details/optimizer/configuration/utils/tariff-kind';
import { TariffPricesForm } from '../types';

export const useMeterTariffPricesConfiguration = (form: UseFormReturn<TariffPricesForm>) => {
  const { t } = useTranslation('channel-settings');
  const { channelId } = useParams<{ channelId: string }>();
  const { selectedInstallationId } = useInstallation();
  const { channel, updateChannelDetails } = useChannelDetailsContext();
  const { data: allTariffs } = useGetTariffs(ChannelTypeInternal.Meter);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleErrors } = useChannelErrors();
  const [updateTariffConfigurations] = useMutation<
    UpdateTariffConfigurationsMutation,
    UpdateTariffConfigurationsMutationVariables
  >(UPDATE_TARIFF_CONFIGURATIONS);

  useEffect(() => {
    const tariffConfigurations = (channel?.data as ChannelMeterInterface)?.sharedChannelSettings?.tariffConfigurations;

    if (tariffConfigurations?.length) {
      form.setValue('tariff', tariffConfigurations[0].tariff);
      form.setValue(
        'prices',
        tariffConfigurations[0].prices.map((x) => ({ price: x.price, pricingTier: x.pricingTier })),
      );
    } else {
      const tariffData = (channel?.data as ChannelMeterInterface).tariff;

      if (tariffData) {
        const temp = tariffKind[tariffData.kind];
        const tariffsOperator = allTariffs?.find((x) => x.operator.toLowerCase() === temp.operator);

        if (tariffsOperator) {
          const tariff = tariffsOperator.tariffs.find((x) => x.name.toLowerCase() === temp.tariff);

          if (tariff) {
            form.setValue('tariff', tariff.name.toUpperCase());

            if (!tariff.hoursRanges.length) {
              form.setValue('prices', [{ price: 0, pricingTier: 0 }]);
            } else {
              if (tariff.hoursRanges.find((x) => x.pricingTier === 3)) {
                form.setValue('prices', [
                  { price: 0, pricingTier: 1 },
                  { price: 0, pricingTier: 2 },
                  { price: 0, pricingTier: 3 },
                ]);
              } else {
                form.setValue('prices', [
                  { price: 0, pricingTier: 1 },
                  { price: 0, pricingTier: 2 },
                ]);
              }
            }
          }
        }
      }
    }
  }, [allTariffs, channel?.data as ChannelMeterInterface]);

  const handleSubmitPrices = (values: TariffPricesForm) => {
    const { tariff, prices } = values;

    turnOnBackdrop();

    updateTariffConfigurations({
      variables: {
        input: {
          channelId,
          installationId: selectedInstallationId,
          tariffConfigurations: [
            {
              tariff,
              prices: prices.map((x) => ({ ...x, price: parseInt(x.price) })),
            },
          ],
        },
      },
      onCompleted: (data) => {
        turnOffBackdrop();
        const responseData = data.updateTariffConfigurations.meterSharedChannelSettings?.tariffConfigurations as
          | TariffConfiguration[]
          | undefined;

        if (responseData) {
          updateChannelDetails((prev) => {
            const temp = cloneDeep(prev);

            if (temp) {
              (temp.data as ChannelMeterInterface).sharedChannelSettings = {
                ...(temp.data as ChannelMeterInterface).sharedChannelSettings,
                tariffConfigurations: responseData,
              };
            }

            return temp;
          });

          toastSuccess({ content: t('toast.editConfigurationSuccess') });
        } else {
          turnOffBackdrop();
          handleErrors(data.updateTariffConfigurations.errors || []);
        }
      },
    });
  };

  return {
    handleSubmitPrices,
  };
};
